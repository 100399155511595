import createApp from '@shopify/app-bridge';
const config = {
    apiKey: "api_key",
    host: "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvb3JkZXItcHJvdGVjdA",
    forceRedirect: true
};
const app = createApp(config);

import { ResourcePicker }  from '@shopify/app-bridge/actions';

const picker = ResourcePicker.create(app, {
  resourceType: ResourcePicker.ResourceType.Product
});
picker.subscribe(ResourcePicker.Action.SELECT, (payload) => {
  console.log(payload.selection);
});
picker.dispatch(ResourcePicker.Action.OPEN);
